import { Link } from "gatsby";
import React from "react";
import Layout from "../../../other/layout";
import SEO from "../../../other/seo";
import { PREVIEW } from "../../../project-config";

export default function DealerPoliciesPage() {
  return (
    <Layout>
      <SEO title="Dealer Policies" />

      <div className="staticContent">
        <h1>DFS Dealer Terms & Conditions</h1>
        <div>
          <p>
            These terms and conditions (“Agreement”), along with
            <Link to="/why-choose-dfs/dfs-policies/privacy-policy/">
              DFS’s Privacy Policy
            </Link>
            apply to your participation in the DFS Dealer Program (the
            “Program”). IF YOU DO NOT AGREE TO THE TERMS OF THIS AGREEMENT, YOU
            CANNOT BE A DFS DEALER. DFS may update or change, augment or enhance
            this Agreement or the DFS Dealer Program without notice to you. By
            becoming a DFS Dealer and accepting this Agreement you agree to be
            bound by these modifications. If you do not agree to the changes
            your sole and exclusive remedy is to terminate your relationship
            with us.
          </p>

          <p></p>
          <h2>Website Conditions</h2>
          <h3>1. Appointment as a Dealer </h3>

          <p>
            DFS hereby appoints the entity listed on the
            <a href={`${PREVIEW}/register.html?vid=20090722001&amp;mt=1`}>
              Dealer Application Form
            </a>
            (hereinafter “you” or “Dealer”) as a non-exclusive Authorized DFS
            Dealer. As a dealer, you have the right to sell DFS products to end
            users in the form and packaging supplied by DFS.
          </p>

          <p></p>
          <h3>2. Placing and Acceptance of Orders </h3>

          <p>
            You agree to submit orders to DFS from time to time for DFS products
            and services. Orders can be placed by internet, phone, fax or mail.
            Orders for cheques should include a bank specification sheet or a
            cheque marked "void" (sorry, no deposit tickets). First time orders
            with new artwork can only be accepted by internet or mail, as faxing
            can distort logo artwork. Custom logos and other customer artwork
            may be redrawn to meet printing specifications, for which there will
            be an additional charge. Imprint information will be printed in our
            standard format, as shown in the catalogue, unless otherwise noted
            on the order. Please specify starting number when applicable. Please
            note, the owner of any trademark, service mark, logo, or copyrighted
            material has the exclusive right to use and reproduce such material.
            You therefore cannot reproduce any unlicensed trademarks, service
            marks, logos, or copyrighted material onto printed materials and
            merchandise via the service offered through this web site without
            authorization from the owner. We may contact you to verify that this
            authorization is in place. All orders are subject to acceptance by
            DFS, as well as these Terms.
          </p>

          <p></p>
          <h3>3. Prices and Payment </h3>

          <p>
            Prices are subject to change without prior notice or obligation.
            Prices do not include shipping if applicable or sales taxes, all of
            which are payable by you. Orders are subject to credit approval.
            Prepayment will speed first-time orders through our credit
            department. For Dealers having received credit approval, terms are
            net 30 days from the date of invoice. In the event that open account
            terms cannot be established, terms are Payment with Order. DFS
            accepts Visa, MasterCard and American Express. Initial orders for
            sales materials do not require prepayment. If an account is past
            due, any new orders will be held until payment is received for the
            outstanding balance. DFS reserves the right to change a Dealer's
            open account status if payment history warrants.
          </p>

          <p></p>
          <h3>4. Delivery </h3>

          <p>
            We'll ship products with standard imprints within three working
            days. Orders with custom artwork and special printing instructions
            will take longer. Stock products ship in 24 hours. Except for
            specialty orders, DFS will prepay the freight charges using standard
            ground shipping and ship to dealer or end-users as specified.
          </p>

          <p></p>
          <h3>5. Support from DFS </h3>

          <p>
            DFS will provide you with free sales tools to assist you in the
            sales of DFS products and services. DFS Dealer Representatives are
            available Monday through Friday 8:00am - 6:00pm ET at 800.490.6327.
            Dealer Representatives will gladly answer all questions concerning
            the DFS program, products, ordering and invoicing. For Custom
            Printing orders and inquiries, call 800.490.6327 during the same
            business hours.
          </p>

          <p></p>
          <h3>6. Warranty </h3>

          <p>
            For 90 days from the day we ship your order, you are covered by our
            100% "Quality Guarantee" which is applicable to all Printed
            Products. We will replace, refund or credit any of our manufactured
            products found to contain printing errors caused by us, or to be
            defective in workmanship or materials. Any printed product created
            using customer supplied print ready files or created using the
            services of our composition design team are also guaranteed to match
            your approved proof in all aspects.
          </p>

          <p></p>
          <h3>7. Limitation of Liability </h3>

          <p>
            IN NO EVENT WILL DFS BE LIABLE FOR ANY INDIRECT, SPECIAL,
            INCIDENTAL, CONSEQUENTIAL OR OTHER DAMAGES OF ANY NATURE OR
            DESCRIPTION IN CONNECTION WITH THIS AGREEMENT OF THE PROGRAM, OR
            DAMAGES RESULTING FROM ANY USE OR MISUSE OF ANY PRODUCT OR ANY ACT
            OR OMISSION BY DFS, EVEN IF DFS HAS BEEN ADVISED OF THE POSSIBILITY
            OF SUCH DAMAGES. DFS CUMULATIVE LIABILITY UNDER THIS AGREEMENT WILL
            BE LIMITED TO THE TOTAL OF ALL FEES PAID FOR THE 12-MONTH PERIOD
            PRECEDING THE EVENT GIVING RISE TO ANY SUCH LIABILITY.
          </p>

          <p></p>
          <h3>8. DFS Materials </h3>

          <p>
            Nothing contained in this Agreement grant, by implication, estoppel,
            or otherwise, any license or right to use any DFS logos, trademarks,
            copyrightable materials or other proprietary materials ("DFS
            Materials"). You shall not, directly or indirectly, undertake or
            engage in the following practices: (a) use or otherwise incorporate
            the word "Deluxe" or "DFS" or any other DFS Materials or variations,
            misspellings or derivatives thereof in the domain name of any
            websites you own or control; (b) make any representations, either
            express or implied, or create an appearance or impression, that (i)
            a visitor to your web site is visiting DFS.ca or any of our
            affiliated company sites (our Sites), (ii) a visitor to our Site is
            visiting your web site or (iii) DFS endorses you or your website or
            your products and services (for example, you are agreeing not to
            "frame" any pages or portions of deluxeforms.ca); (c) purchase, bid,
            or register for placement of any DFS or our affiliated companies
            trademarks (including "Deluxe" and "DFS"), or for any variations or
            deliberate misspellings thereof, within any search engine or
            directories; or (d) use predatory advertising methods designed to
            generate traffic from websites that do not have approval for the
            online promotion of our products or services. Predatory advertising
            is defined as any method that creates or overlays links or banners
            on web sites, spawns browser windows, or any method invented to
            generate traffic from a website without that website owner's
            expressed, written permission.
          </p>

          <p></p>
          <h3>9. Confidentiality </h3>

          <p>
            A party may from time to time disclose (the "Disclosing Party")
            confidential information ("Confidential Information") to the other
            party (the "Recipient") in connection with the business relationship
            set forth herein. Recipient agrees to access, use and disclose the
            Confidential Information of Disclosing Party only in connection with
            fulfilling its obligations under this Agreement and for no other
            purposes, except that DFS may access, use and disclose such
            information to protect against actual or potential fraud or
            unauthorized transactions, to comply with legal requirements or for
            other purposes permitted by law. Confidential Information shall not
            be construed to mean any information which is: (a) or becomes part
            of the public domain without violation of this Section 6; (b) known
            by Recipient prior to disclosure by Disclosing Party; (c) rightfully
            received by Recipient from a third party having the right to
            disclose such information; or (d) developed by Recipient independent
            of Disclosing Party's otherwise Confidential Information.
          </p>

          <p></p>
          <h3>10. Indemnity</h3>

          <p>
            You agree to defend, indemnify and hold DFS and its affiliated
            companies harmless from and against any and all liabilities, claims,
            damages, penalties, causes of action, costs and expenses (including,
            without limitation, attorneys' fee and costs of litigation) incurred
            by or asserted against DFS or any of its affiliated companies that
            arise out of or are incidental to your conduct of business under
            this Agreement or the products or services provided hereunder.
          </p>

          <p></p>
          <h3>11. Term and Termination </h3>

          <p>
            These Terms shall be effective upon the earlier of DFS notifying you
            of your acceptance into DFS Program or DFS acceptance of your
            initial order and shall expire upon this Agreement being terminated
            by you or DFS as provided herein (the "Term"). Either party may at
            its sole discretion at any time terminate the Agreement with or
            without cause upon written notice to the other party. No termination
            of this Agreement shall affect the respective obligations or rights
            of the parties accruing hereunder prior to the effective date of
            such termination and, in addition, the parties hereby agree all
            terms and conditions that should by their nature survive termination
            of this Agreement shall so survive. Because these Terms may be
            terminated by either party at any time, with or without cause, you
            acknowledge and agree that (i) there is no commitment that your
            relationship with DFS shall exist for any minimum period and (ii)
            all investments and commitments made by you or expenses incurred by
            you are done so by you at your own risk and with the understanding
            that you shall not look to DFS to recover or recoup any such
            investments, commitments or expenses or any amounts for expected
            future profits in the event either you or DFS terminates this
            Agreement.
          </p>

          <p></p>
          <h3>12. Miscellaneous </h3>

          <p>
            This Agreement shall be construed with the laws of state of
            Minnesota, excluding its conflict of law provisions. Any action
            relating to this Agreement must be brought in the federal or state
            courts located in Ramsey County, Minnesota, and you irrevocably
            consent to the jurisdiction of such courts. You may not assign this
            Agreement, by operation of law or otherwise, without DFS’s prior
            written consent. Subject to that restriction, this Agreement will be
            binding on, inure to the benefit of, and enforceable against the
            parties and their respective successors and assigns. If any clause
            of these Terms is declared illegal or invalid, the balance of these
            Terms shall remain enforceable, the clauses herein being severable.
            No act or failure to act by DFS hereunder shall constitute a waiver
            by DFS of any term or condition herein. No course of dealing between
            the parties will be deemed effective to modify or discharge any
            rights or obligations of either party. This Agreement and any
            documents incorporated herein by reference constitutes the entire
            agreement between the parties with respect to the subject matter
            hereof and supersedes any prior or contemporaneous agreements or
            understandings, written or oral, concerning the subject matter
            hereof. This Agreement constitute the final and complete agreement
            between you and DFS regarding the subject matter herein and
            supersedes all prior and contemporaneous, understandings, agreements
            and communications, written or oral, with respect to the subject
            matter hereof.
          </p>

          <p></p>
          <h2>Website Terms Of Use</h2>

          <p>
            Please read these terms of use (“Terms of Use”) carefully. These
            Terms of Use govern your access and use of this website (“Site”).
            “DFS” or “we” refers to DFS. “You” or “your” refers to the customer
            accessing this Site. By accessing or using this Site you agree to be
            bound by these Terms of Use and to any additional guidelines,
            restrictions, or rules that may be posted in connection with
            specific sections or services of this Site. All such additional
            posted guidelines, restrictions, or rules are hereby incorporated by
            reference into these Terms of Use. DFS reserves the right to make
            changes to this Site and to these Terms of Use at any time without
            prior notice. You should review this Agreement each time you access
            this Site.
          </p>

          <p></p>

          <p>
            1. You may download material displayed on the Site for
            non-commercial, personal use only, provided you do not remove or
            alter any trademark, copyright or other proprietary notices
            contained on the materials. You may not, however, distribute,
            modify, transmit, reuse, report, or use the contents of the Site for
            public or commercial purposes, including the text and images,
            without DFS's prior written permission.
          </p>
          <p></p>
          <p>
            2. You should assume that everything you see or read on the Site is
            copyrighted unless otherwise noted and may not be used except as
            provided in these Terms of Use (and the text on the Site) without
            the prior written permission of DFS. DFS neither warrants nor
            represents that your use of materials displayed on the Site will not
            infringe rights of any third parties.
          </p>
          <p></p>
          <p>
            3. DFS makes no warranties or representations as to the accuracy or
            completeness of information and materials contained on the Site and
            DFS assumes no liability or responsibility for any inaccuracies,
            errors or omissions in the content on the Site.
          </p>
          <p></p>
          <p>
            4. Your use and browsing of the Site is at your risk. Neither DFS
            nor any other party involved in creating, producing, maintaining or
            delivering the Site is liable for any direct, indirect, special,
            incidental, consequential or other damages of any nature or
            description arising out of your access to, or use of, the Site.
            Without limiting the foregoing, everything on the Site is provided
            to you “AS IS” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESSED OR
            IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
            NON-INFRINGEMENT. Please note that some jurisdictions may not allow
            the exclusion of implied warranties, so some of the above exclusions
            may not apply to you. DFS also assumes no responsibility, and shall
            not be liable for any damages to, or viruses that may infect, your
            computer equipment or other property on account of your access to,
            use of, or browsing on the Site or your downloading of any
            materials, data, text or images from the Site.
          </p>
          <p></p>
          <p>
            5. Any communication or material you transmit to the Site by
            electronic mail or otherwise, including any data, questions,
            comments, suggestions, or the like is, and will be treated as,
            non-confidential and non-proprietary. Anything you transmit or post
            may be used by DFS or its affiliates for any purpose, including but
            not limited to reproduction, disclosure, transmission, publication,
            broadcast and posting. Furthermore, DFS is free to use, without
            limitation or restriction, any ideas, concepts, know-how or
            techniques contained in any communication you send to or through the
            Site for any purpose whatsoever, including but not limited to
            developing, manufacturing and marketing products and services using
            such information.
          </p>
          <p></p>
          <p>
            6. Images of people or products displayed on the Site are either the
            property of, or used with permission by, DFS. The use of these
            images by you, or anyone else authorized by you, is prohibited. Any
            unauthorized use of the images may violate applicable laws,
            including copyright laws, trademark laws, the laws of privacy and
            publicity.
          </p>
          <p></p>
          <p>
            7. DFS has not reviewed all of the sites linked to the Site and is
            not responsible for the contents of any off-site pages or any other
            sites linked to the Site or the products and services offered by or
            through such Sites or the privacy, data security or other business
            practices of those owning or maintaining such sites. Your linking
            to, and access and use of, any other off-Site pages or other sites
            is at your own risk.
          </p>
          <p></p>
          <p>
            8. You are prohibited from posting or transmitting to DFS or the
            Site any unauthorized or misappropriated information, materials or
            images or unlawful, threatening, libelous, defamatory, obscene,
            scandalous, inflammatory, pornographic, or profane information,
            materials or images or any information, material or image that could
            constitute or encourage conduct that would be considered a criminal
            offense, give rise to civil liability, or otherwise violate any law.
            It is DFS's policy to fully cooperate with any law enforcement
            authorities or court order requesting or directing DFS to disclose
            the identity of anyone posting or transmitting any such information,
            materials or images.
          </p>
          <p></p>
          <p>
            9. Except as noted otherwise in the text of the Site, the
            trademarks, logos, service marks and all copyrightable materials
            displayed on the Site constitute intellectual property owned by DFS
            or which DFS has the right to use on the Site (the “DFS Intellectual
            Property”). Nothing contained on the Site should be construed as
            granting, by implication, estoppel or otherwise, any license or
            right to use any DFS Intellectual Property displayed on the Site
            without the prior written permission of DFS. Unauthorized use of the
            DFS Intellectual Property displayed on the Site, or any other
            content on the Site, is strictly prohibited. Please be advised that
            DFS will aggressively enforce its intellectual property rights to
            the fullest extent of the law, including the seeking of criminal
            prosecution.
          </p>
        </div>
      </div>
    </Layout>
  );
}
